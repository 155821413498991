import { makeObservable, observable, action } from "mobx";
import RequestFactory from "../request/request-factory";

import UserStore from "./UserStore";
import ReportsStore from "./ReportsStore";
import NotificationStore from "./NotificationsStore";
import PopupStore from "./PopupStore";
import AdminStore from "./AdminStore";
import { MarketplacesType } from "../types";

export class RootStore {
  createRequest: RequestFactory["createRequest"] = Promise.resolve;

  isLoading: boolean = false;
  marketplace?: MarketplacesType;

  userStore: UserStore;

  reportsStore: ReportsStore;
  notificationStore: NotificationStore;
  popupStore: PopupStore;
  adminStore: AdminStore;

  constructor() {
    this.userStore = new UserStore(this);
    this.reportsStore = new ReportsStore(this);
    this.notificationStore = new NotificationStore(this);
    this.popupStore = new PopupStore(this);
    this.adminStore = new AdminStore(this);

    makeObservable(this, {
      isLoading: observable,
      marketplace: observable,
      setLoading: action,
      setMarketplace: action,
    });
  }

  setCreateRequest(createRequest: RequestFactory["createRequest"]) {
    this.createRequest = createRequest;
  }

  public setLoading = (value: boolean) => {
    this.isLoading = value;
  };

  public setMarketplace = (value: MarketplacesType) => {
    this.marketplace = value;
  };
}
