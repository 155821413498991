import { IRequestConfigList, RequestMethods } from "./request-factory";

const RequestConfigList: IRequestConfigList = {
  login: {
    method: RequestMethods.POST,
    path: "/api/user/login",
  },
  demoLogin: {
    method: RequestMethods.GET,
    path: "/api/user/demo",
  },
  logout: {
    method: RequestMethods.GET,
    path: "/api/user/logout",
  },
  registration: {
    method: RequestMethods.POST,
    path: "/api/user/register",
  },
  getReports: {
    method: RequestMethods.GET,
    path: "/api/reports",
  },
  getEmbedToken: {
    method: RequestMethods.GET,
    path: "/api/powerbi/embed_token",
  },
  adminGetUsers: {
    method: RequestMethods.GET,
    path: "/api/admin/users",
  },
  adminUpdateUser: {
    method: RequestMethods.POST,
    path: "/api/admin/user/update",
  },
  adminAddUser: {
    method: RequestMethods.POST,
    path: "/api/admin/user/add",
  },
  adminGetReports: {
    method: RequestMethods.GET,
    path: "/api/admin/user/reports",
  },
  adminAddReport: {
    method: RequestMethods.POST,
    path: "/api/admin/user/add-report",
  },
  adminUpdateReport: {
    method: RequestMethods.POST,
    path: "/api/admin/user/update-report",
  },
  adminDeleteReport: {
    method: RequestMethods.POST,
    path: "/api/admin/user/delete-report",
  },
  adminGetMarketplaceTypes: {
    method: RequestMethods.GET,
    path: "/api/admin/marketplace",
  },
  adminGetReportTypes: {
    method: RequestMethods.GET,
    path: "/api/admin/report-types",
  },
};

export default RequestConfigList;
