import { useCallback, useMemo } from "react";
import { createBrowserHistory } from "history";
import { parse, stringify } from "qs";

export const useQuery = () => {
  const history = createBrowserHistory();

  const replace = (url: string): void => history.replace(url);

  const push = (url: string): void => history.push(url);

  const getQuery = useCallback((name: string): string | null => {
    const currentUrl = window.location.href;
    const newUrl = new URL(currentUrl);
    const urlParams = newUrl.searchParams;

    return urlParams.get(name);
  }, []);

  const queryPatch = useCallback(
    (values: Record<string, unknown>, useReplace: boolean = false): void => {
      const parameters =
        parse(history.location.search.replace(/^[?]+/, "")) || {};

      (useReplace ? replace : push)(
        `${history.location.pathname}${stringify(
          Object.assign({}, parameters, values),
          {
            addQueryPrefix: true,
            skipNulls: true,
          }
        )}`
      );
    },
    []
  );

  return useMemo(
    () => ({
      queryPatch,
      getQuery,
    }),
    [queryPatch, getQuery]
  );
};
