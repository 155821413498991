import { Theme, SxProps } from "@mui/material/styles";

const styles: Record<string, SxProps<Theme>> = {
  root: {
    maxWidth: "700px",
    margin: "24px",
    display: "flex",
  },
  icon: {
    width: "24px",
    height: "24px",
  },
  action: {
    display: "flex",
    mb: 3,
  },
};

export default styles;
