import React, { FC } from "react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import styles from "./styles";
import UserDetailForm from "../user-detail-form/UserDetailForm";
import useStore from "../../hooks/useStore";
import { IUser } from "../../store/AdminStore";
import UserReportsForm from "../user-reports-form/UserReportsForm";

const cells = [
  {
    id: "email",
    label: "email",
  },
  {
    id: "fio",
    label: "ФИО",
  },
  {
    id: "phone",
    label: "телефон",
  },
  {
    id: "edit",
    label: "",
  },
  {
    id: "report",
    label: "",
  },
];

interface IUsersListProps {
  users: Array<IUser>;
}

const UsersList: FC<IUsersListProps> = ({ users }) => {
  const { popupStore } = useStore();

  const onEdit = (user: IUser) => {
    popupStore.openPopup({
      props: { size: "md" },
      contentProps: { user },
      content: UserDetailForm,
    });
  };

  const onEditReports = (user: IUser) => {
    popupStore.openPopup({
      props: { size: "lg" },
      contentProps: { userId: user.user_id },
      content: UserReportsForm,
    });
  };

  if (!users || !users.length) {
    return null;
  }

  return (
    <TableContainer component={Paper} sx={styles.table}>
      <Table size="small">
        <TableHead>
          <TableRow>
            {cells.map((cell: { id: string; label: string }) => (
              <TableCell align="left" key={cell.id}>
                <Typography variant="body1">{cell.label}</Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow sx={styles.tableRow} key={user.user_id}>
              <TableCell width="30%" align="left">
                {user.email}
              </TableCell>
              <TableCell width="30%" align="left">
                {user.fio}
              </TableCell>
              <TableCell width="30%" align="left">
                {user.phone}
              </TableCell>

              <TableCell width="5%" align="left">
                <Button size="small" onClick={() => onEdit(user)}>
                  Редактировать
                </Button>
              </TableCell>

              <TableCell width="5%" align="left">
                <Button size="small" onClick={() => onEditReports(user)}>
                  Отчеты
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UsersList;
