import * as yup from "yup";

/* eslint-disable no-template-curly-in-string */
yup.setLocale({
  mixed: {
    required: "Поле обязательно для заполнение",
    notType: ({ type, originalValue }) => {
      if (type === "number" && originalValue) {
        return "Только числа";
      }

      if (originalValue) {
        return "Неправильный формат";
      }

      return "Поле обязательно для заполнение";
    },
  },
});

yup.addMethod<yup.StringSchema>(
  yup.string,
  "isPhone",
  function (message?: string) {
    return this.test(
      "is-phone",
      message || "Некорректный номер телефона",
      (value) => {
        if (!value) {
          return false;
        }

        const clearedValue = value.replace(/[^0-9]/g, "");
        return clearedValue.length === 11;
      }
    );
  }
);

yup.addMethod<yup.NumberSchema>(
  yup.number,
  "length",
  function (limit: number, message?: string) {
    return this.test(
      "number-has-exact-number-of-digits",
      message || `\${path} должен состоять из ${limit} символов`,
      (value) => {
        if (!value) {
          return false;
        }

        return value.toString().length === limit;
      }
    );
  }
);

declare module "yup" {
  //@ts-ignore
  interface StringSchema<
    TType extends yup.Maybe<string> = string | undefined,
    TContext extends yup.AnyObject = yup.AnyObject,
    TOut extends TType = TType
    //@ts-ignore
  > extends yup.BaseSchema<TType, TContext, TOut> {
    isPhone(message?: string): StringSchema<TType, TContext>;
  }
}

export default yup;
