import { FC, memo } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";

interface PowerBiReportProps {
  email: string;
  accessToken: string;
  embedUrl: string;
}

const PowerBiReport: FC<PowerBiReportProps> = ({
  email,
  accessToken,
  embedUrl,
}) => {
  const embedUrlParams = new URL(embedUrl).searchParams;
  const embedReportId = embedUrlParams.get("reportId");

  const filter = {
    $schema: "http://powerbi.com/product/schema#basic",
    filterType: 1,
    target: {
      table: "info",
      column: "email",
    },
    operator: "eq",
    values: [email],
  };

  const config = {
    type: "report",
    tokenType: models.TokenType.Embed,
    accessToken: accessToken,
    embedUrl: embedUrl,
    id: embedReportId,
    filters: [filter],
    permissions: models.Permissions.All,
    settings: {
      panes: {
        filters: { visible: false },
        pageNavigation: { visible: true },
      },
      bars: {
        statusBar: { visible: true },
      },
    },
    visualName: "",
  };

  if (!embedReportId) {
    return;
  }

  return (
    <PowerBIEmbed
      //@ts-ignore
      embedConfig={config}
      cssClassName="report-container"
    />
  );
};

export default memo(PowerBiReport);
