import React, { FC } from "react";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import styles from "./styles";

import { IReport } from "../../store/AdminStore";

const cells = [
  {
    id: "type_report",
    label: "Тип отчета",
  },
  {
    id: "type_marketplace",
    label: "Тип магазина",
  },
  {
    id: "url_report",
    label: "Ссылка на отчет",
  },
  {
    id: "edit",
    label: "",
  },
  {
    id: "delete",
    label: "",
  },
];

interface IUsersListProps {
  reports: Array<IReport>;
  onUpdateReport: (report: IReport) => void;
  onDeleteReport: (report: IReport) => void;
}

const UserReportsList: FC<IUsersListProps> = ({
  reports,
  onUpdateReport,
  onDeleteReport,
}) => {
  if (!reports.length) {
    return (
      <Box>
        <Typography>У пользователя пока нет отчетов!</Typography>
      </Box>
    );
  }

  return (
    <TableContainer component={Paper} sx={styles.table}>
      <Table size="small">
        <TableHead>
          <TableRow>
            {cells.map((cell: { id: string; label: string }) => (
              <TableCell align="left" key={cell.id}>
                <Typography variant="body1">{cell.label}</Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {reports.map((report) => (
            <TableRow sx={styles.tableRow} key={report.type_report}>
              <TableCell width="30%" align="left">
                {report.note_r}
              </TableCell>
              <TableCell width="30%" align="left">
                {report.note_m}
              </TableCell>
              <TableCell width="30%" align="left">
                <a target="_blank" href={report.url_report}>
                  ссылка
                </a>
              </TableCell>
              <TableCell width="5%" align="left">
                <IconButton size="small" onClick={() => onUpdateReport(report)}>
                  <EditIcon color="primary" />
                </IconButton>
              </TableCell>
              <TableCell width="5%" align="left">
                <IconButton size="small" onClick={() => onDeleteReport(report)}>
                  <DeleteIcon color="primary" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UserReportsList;
