import { FC, memo } from "react";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

import { Control, Controller, FieldError } from "react-hook-form";
import { InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";

interface IMuiFormSelectProps {
  name: string;
  label: string;
  options: Array<{ value: string; label: string }>;
  error?: FieldError;
  control: Control<any>;
}

const MuiFormSelect: FC<IMuiFormSelectProps> = ({
  options,
  label,
  name,
  error,
  control,
}) => {
  return (
    <FormControl error={Boolean(error?.message)} sx={{ width: "100%" }}>
      {label && (
        <InputLabel id={`${name}-label`} variant="outlined">
          {label}
        </InputLabel>
      )}
      <Controller
        render={({ field }) => (
          <Select
            MenuProps={{ variant: "menu" }}
            variant="outlined"
            id={name}
            input={<OutlinedInput label={label} />}
            labelId={`${name}-label`}
            {...field}
          >
            {options.map((option) => (
              <MenuItem value={option.value} key={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        )}
        name={name}
        control={control}
      />
      {error?.message && <FormHelperText>{error.message}</FormHelperText>}
    </FormControl>
  );
};

export default memo(MuiFormSelect);
