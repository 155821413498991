import { FC, memo } from "react";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

import { Control, Controller, FieldError } from "react-hook-form";
import { Checkbox, FormControlLabel } from "@mui/material";

interface IMuiFormCheckbox {
  name: string;
  label: string;
  error?: FieldError;
  control: Control<any>;
}

const MuiFormCheckbox: FC<IMuiFormCheckbox> = ({
  label,
  name,
  error,
  control,
}) => {
  return (
    <FormControl error={Boolean(error?.message)} sx={{ width: "100%" }}>
      <Controller
        render={({ field: { value, ...otherField } }) => (
          <FormControlLabel
            control={<Checkbox checked={value} id={name} {...otherField} />}
            label={label}
          />
        )}
        name={name}
        control={control}
      />

      {error?.message && <FormHelperText>{error.message}</FormHelperText>}
    </FormControl>
  );
};

export default memo(MuiFormCheckbox);
